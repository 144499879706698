import * as debounce from 'lodash/debounce';
import * as isEqual from 'lodash/isEqual';
import * as isMatch from 'lodash/isMatch';

import { IWixAPI } from '@wix/native-components-infra/dist/src/types/types';
import {
  ClientSearchSDK,
  ISearchDocument,
  ISearchDocumentType,
  ISearchRequest,
  ISearchResponse,
  ISearchResponseTotals,
  ISampleResponse,
  ISearchSDKDemoContentOptions,
  SearchDocumentType,
} from '@wix/client-search-sdk';

import initSchemaLogger, {
  Logger as IBILogger,
} from '@wix/bi-logger-wix-search-widget';
import { ISearchResultsSettings } from '@wix/search-results-settings-definitions';

import { IWidgetControllerConfig } from './platform.types';

import { ISearchLocation } from './location';

import { Spec } from '../lib/specs';
import Experiments from '@wix/wix-experiments';

import { SearchRequestStatus } from '../types/types';
import {
  createBICorrelationId,
  BICorrelationIdKey,
  BICorrelationIdStore,
  getBIDefaults,
  getBITotals,
  getAbsoluteDocumentIndex,
} from './bi';
import { APP_DEFINITION_ID, WIDGET_ID } from '../lib/contants';

import { IFedopsLogger } from './types';
import { ISearchResultsControllerProps } from './SearchResultsControllerStore.types';
import { IReportError } from './monitoring/sentry';

function equalSearchRequests(
  searchRequest1: ISearchRequest,
  searchRequest2: ISearchRequest,
) {
  return isEqual(searchRequest1, searchRequest2);
}

function withDocumentType(
  searchRequest: ISearchRequest,
  documentTypes: ISearchDocumentType[],
): ISearchRequest {
  if (searchRequest.documentType) {
    return searchRequest;
  }

  return {
    ...searchRequest,
    documentType: documentTypes[0] && documentTypes[0].documentType,
  };
}

const SAMPLES_SIZE = {
  MOBILE: 4,
  DESKTOP: 3,
};

export class SearchResultsControllerStore {
  private readonly wixCodeApi: IWixAPI;
  private readonly setComponentProps: (
    props: Partial<ISearchResultsControllerProps>,
  ) => void;

  private readonly fedopsLogger: IFedopsLogger;
  private readonly biLogger: IBILogger;
  private readonly biCorrelationIdStore: BICorrelationIdStore;
  private readonly reportError: IReportError;
  private readonly searchSDK: ClientSearchSDK;
  private readonly searchLocation: ISearchLocation;
  private readonly experiments: Experiments;

  // private settingsEventHandler: ISettingsEventHandler;
  private demoContentOptions: ISearchSDKDemoContentOptions;
  private state: Partial<ISearchResultsControllerProps>;

  constructor(
    {
      appParams,
      platformAPIs,
      wixCodeApi,
      setProps,
      config,
      experiments,
      reportError,
      searchSDK,
      searchLocation,
      siteLanguage,
    }: IWidgetControllerConfig,
    settings: ISearchResultsSettings,
  ) {
    this.wixCodeApi = wixCodeApi;
    this.setComponentProps = setProps;
    this.reportError = reportError;
    this.searchSDK = searchSDK;
    this.searchLocation = searchLocation;
    this.experiments = experiments;

    const cssBaseUrl = appParams.baseUrls.staticsBaseUrl;

    this.state = {
      language: siteLanguage,
      cssBaseUrl,
      searchResultsAbsoluteUrl: null,
      settings,
      experiments: experiments.all(),
      searchRequest: this.getSearchRequestFromLocationPath(
        wixCodeApi.location.path,
        settings.itemsPerPage,
      ),
      searchRequestStatus: SearchRequestStatus.Initial,
      documentTypes: [],
      isLoaded: false,
      onDocumentTypeChange: this.handleDocumentTypeChange,
      onQuerySubmit: this.handleQuerySubmit,
      onPageChange: this.handlePageChange,
      onDocumentClick: this.handleDocumentClick,
      onAppLoaded: this.handleAppLoaded,
      // TODO: cleanup when resolved https://github.com/wix-private/native-components-infra/pull/28
      viewMode: wixCodeApi.window.viewMode,
      isDemoContent: wixCodeApi.window.viewMode !== 'Site',
      isMobile: wixCodeApi.window.formFactor === 'Mobile',
    };

    // this.initSettingsEventHandler(appPublicData);

    if (this.state.isDemoContent) {
      this.setDemoContentOptions({
        shouldMockDocumentTypes: false,
        shouldHaveSearchResults: true,
      });
    }

    // NOTE: http://wixplorer.wixpress.com/out-of-iframe/guides/Reference/Fedops
    this.fedopsLogger = platformAPIs.fedOpsLoggerFactory.getLoggerForWidget({
      appId: APP_DEFINITION_ID,
      widgetId: WIDGET_ID,
    });

    // NOTE: editor version of biLoggerFactory does not add some required defaults,
    // so set it manually
    this.biLogger = initSchemaLogger(platformAPIs.biLoggerFactory())({
      defaults: getBIDefaults(platformAPIs, wixCodeApi.window.viewMode),
    });
    this.biCorrelationIdStore = new BICorrelationIdStore(platformAPIs);

    wixCodeApi.location.onChange(({ path }) => {
      const stateSearchRequest = this.state.searchRequest;
      const locationSearchRequest = this.getSearchRequestFromLocationPath(
        path,
        stateSearchRequest.paging.pageSize,
      );

      if (equalSearchRequests(locationSearchRequest, stateSearchRequest)) {
        return;
      }

      void this.changeSearchRequest(locationSearchRequest);
    });
  }

  private static getEmptySearchResponse() {
    return {
      documents: [],
      totalResults: 0,
    };
  }

  private getSearchRequestFromLocationPath(path: string[], pageSize: number) {
    const locationSearchRequest = this.searchLocation.decodePath(
      /**
       * first item in location path -- is "page name"
       * we should decode all parts after
       */
      path.slice(1).join('/'),
    );

    return this.searchLocation.toSDKSearchRequest(
      locationSearchRequest,
      pageSize,
    );
  }

  private isSSR() {
    return this.wixCodeApi.window.rendering.env === 'backend';
  }

  private setDemoContentOptions(
    partialOptions: Partial<ISearchSDKDemoContentOptions>,
  ) {
    if (
      this.demoContentOptions &&
      isMatch(this.demoContentOptions, partialOptions)
    ) {
      return;
    }

    this.demoContentOptions = {
      ...this.demoContentOptions,
      ...partialOptions,
    };
    this.searchSDK.useDemoContent(this.demoContentOptions);
  }

  private setState(partialState: Partial<ISearchResultsControllerProps>) {
    this.state = {
      ...this.state,
      ...partialState,
    };

    this.setComponentProps(this.state);
  }

  private async search(
    searchRequest: ISearchRequest,
  ): Promise<{
    searchResponse: ISearchResponse;
    searchResponseTotals: ISearchResponseTotals;
    searchSampleResponse: ISampleResponse;
  }> {
    const startTime = Date.now();

    const biProps = {
      isDemo: this.state.isDemoContent,
      target: searchRequest.query,
      documentType: searchRequest.documentType,
      correlationId: this.biCorrelationIdStore.get(
        BICorrelationIdKey.SearchSubmit,
      ),
    };

    try {
      void this.biLogger.searchRequestStarted(biProps);

      const shouldShowSamples =
        this.experiments.enabled(Spec.SearchSamples) &&
        searchRequest.documentType === SearchDocumentType.All;

      const [
        searchResponse,
        searchResponseTotals,
        searchSampleResponse,
      ] = await Promise.all<
        ISearchResponse,
        ISearchResponseTotals,
        ISampleResponse
      >([
        shouldShowSamples
          ? Promise.resolve({
              documents: [],
              documentType: SearchDocumentType.All,
              totalResults: 0,
            })
          : this.searchSDK.search(searchRequest),
        this.searchSDK.getTotals(searchRequest),
        shouldShowSamples
          ? this.searchSDK.getSample({
              query: searchRequest.query,
              limit: this.state.isMobile
                ? SAMPLES_SIZE.MOBILE
                : SAMPLES_SIZE.DESKTOP,
            })
          : Promise.resolve({ samples: [] }),
      ]);

      if (shouldShowSamples) {
        searchResponse.totalResults =
          searchResponseTotals[SearchDocumentType.All];
      }

      void this.biLogger.searchRequestFinished({
        ...biProps,
        success: true,
        loadingDuration: Date.now() - startTime,
        resultCount: searchResponse.totalResults,
        resultsArray: getBITotals(searchResponseTotals),
      });

      return { searchResponse, searchResponseTotals, searchSampleResponse };
    } catch (error) {
      void this.biLogger.searchRequestFinished({
        ...biProps,
        success: false,
        error: error.toString(),
        loadingDuration: Date.now() - startTime,
      });

      throw error;
    }
  }

  private async changeSearchRequest(
    searchRequestRaw: ISearchRequest,
  ): Promise<void> {
    this.setState({
      searchRequestStatus: SearchRequestStatus.Loading,
    });

    const searchRequest = withDocumentType(
      searchRequestRaw,
      this.state.documentTypes,
    );

    try {
      const {
        searchResponse,
        searchResponseTotals,
        searchSampleResponse,
      } = await this.search(searchRequest);
      const { searchResultsAbsoluteUrl } = this.state;

      const searchSamples = searchSampleResponse.samples.map(s => ({
        ...s,
        url: this.searchLocation.buildSearchResultsUrl(
          searchResultsAbsoluteUrl,
          {
            query: searchRequest.query,
            documentType: s.documentType,
          },
        ),
      }));

      // NOTE: to override previous value with empty value set null (undefined will be ignored)
      this.setState({
        searchRequest,
        searchResponse,
        searchResponseTotals: searchResponseTotals || null,
        searchSamples,
        searchRequestStatus: SearchRequestStatus.Loaded,
      });
    } catch (error) {
      this.setState({
        searchRequest,
        searchResponse: SearchResultsControllerStore.getEmptySearchResponse(),
        searchResponseTotals: null,
        searchSamples: [],
        searchRequestStatus: SearchRequestStatus.Failed,
      });

      this.reportError(error);
    }
  }

  private readonly changeSearchRequestLazy: (
    request: ISearchRequest,
  ) => void = debounce(this.changeSearchRequest, 500);

  updateSettings(settings: ISearchResultsSettings) {
    const prevSettings = this.state.settings;

    this.setState({
      settings,
    });

    if (prevSettings.itemsPerPage !== settings.itemsPerPage) {
      this.changeSearchRequestLazy({
        ...this.state.searchRequest,
        paging: {
          page: 1,
          pageSize: settings.itemsPerPage,
        },
      });
    }

    // https://github.com/wix-private/site-search/issues/153
    // this.settingsEventHandler.updateData(appPublicData);
  }

  getSearchSDK(): ClientSearchSDK {
    return this.searchSDK;
  }

  private applySearchRequest(searchRequest: ISearchRequest) {
    if (
      this.state.isDemoContent ||
      equalSearchRequests(this.state.searchRequest, searchRequest)
    ) {
      void this.changeSearchRequest(searchRequest);
      return;
    }

    void this.searchLocation.navigateToSearchResults(
      this.searchLocation.toLocationSearchRequest(searchRequest),
    );
  }

  changeDocumentType = (documentType: SearchDocumentType) => {
    const searchRequest = this.state.searchRequest;

    this.applySearchRequest({
      ...searchRequest,
      documentType,
      paging: {
        ...searchRequest.paging,
        page: 1,
      },
    });
  };

  changeQuery = (query: string) => {
    const searchRequest = this.state.searchRequest;

    this.applySearchRequest({
      ...searchRequest,
      query,
      paging: {
        ...searchRequest.paging,
        page: 1,
      },
    });
  };

  changePage = (page: number) => {
    const searchRequest = this.state.searchRequest;

    this.applySearchRequest({
      ...searchRequest,
      paging: {
        ...searchRequest.paging,
        page,
      },
    });
  };

  updateDemoMode(data: {
    shouldHaveSearchResults: boolean;
    shouldSetNonAllDocumentType: boolean;
  }) {
    const { shouldHaveSearchResults, shouldSetNonAllDocumentType } = data;

    let searchRequest = this.state.searchRequest;
    let isSearchRequestChanged = false;
    let isDemoContentOptionsChanged = false;

    if (
      shouldHaveSearchResults !==
      this.demoContentOptions.shouldHaveSearchResults
    ) {
      this.setDemoContentOptions({
        shouldHaveSearchResults,
      });

      isDemoContentOptionsChanged = true;
    }

    if (shouldSetNonAllDocumentType) {
      const documentTypeSource = this.state.documentTypes.find(
        ({ documentType }) => documentType !== SearchDocumentType.All,
      );

      if (
        documentTypeSource &&
        documentTypeSource.documentType !== searchRequest.documentType
      ) {
        searchRequest = {
          ...searchRequest,
          documentType: documentTypeSource.documentType,
        };

        isSearchRequestChanged = true;
      }
    }

    if (isDemoContentOptionsChanged || isSearchRequestChanged) {
      this.applySearchRequest(searchRequest);
    }
  }

  private readonly handleAppLoadStarted = () => {
    this.fedopsLogger.appLoadStarted();
  };

  private readonly handleAppLoaded = () => {
    this.fedopsLogger.appLoaded();
  };

  private readonly handleDocumentTypeChange = (
    documentType: SearchDocumentType,
    biParams?: { source: string },
  ): void => {
    const correlationId = this.biCorrelationIdStore.get(
      BICorrelationIdKey.SearchSubmit,
    );

    void this.biLogger.documentTypeChange({
      isDemo: this.state.isDemoContent,
      target: documentType,
      correlationId,
      ...biParams,
    });

    this.changeDocumentType(documentType);
  };

  private readonly handleQuerySubmit = (query: string): void => {
    const correlationId = createBICorrelationId();

    this.biCorrelationIdStore.set(
      BICorrelationIdKey.SearchSubmit,
      correlationId,
    );

    // TODO: add clickOrigin
    void this.biLogger.searchSubmit({
      isDemo: this.state.isDemoContent,
      target: query,
      correlationId,
    });

    this.changeQuery(query);
  };

  private readonly handlePageChange = (page: number) => {
    // const correlationId = this.biCorrelationIdStore.get(
    //   BICorrelationIdKey.SearchSubmit,
    // );

    // TODO: uncomment, when need `pageChange` event.
    // void this.biLogger.pageChange({
    //   isDemo: this.state.isDemoContent,
    //   target: page,
    //   correlationId,
    // });

    this.changePage(page);
  };

  private readonly handleDocumentClick = (
    document: ISearchDocument,
    index: number,
  ): void => {
    const { isDemoContent, searchRequest, searchResponseTotals } = this.state;
    const correlationId = this.biCorrelationIdStore.get(
      BICorrelationIdKey.SearchSubmit,
    );

    void this.biLogger.documentClick({
      isDemo: isDemoContent,
      target: document.title,
      pageUrl: document.url,
      documentType: searchRequest.documentType,
      correlationId,
      searchIndex: getAbsoluteDocumentIndex(searchRequest.paging, index),
      resultsArray: getBITotals(searchResponseTotals),
    });

    this.wixCodeApi.location.to(document.relativeUrl);
  };

  async setInitialState(): Promise<void> {
    this.handleAppLoadStarted();

    if (!this.biCorrelationIdStore.has(BICorrelationIdKey.SearchSubmit)) {
      // NOTE: there are no correlationId from previous submit, so create new one
      this.biCorrelationIdStore.set(
        BICorrelationIdKey.SearchSubmit,
        createBICorrelationId(),
      );
    }

    const startTime = Date.now();

    this.setState({
      searchRequestStatus: SearchRequestStatus.Loading,
    });
    void this.biLogger.loadingStarted({});

    try {
      const [searchResultsAbsoluteUrl, documentTypes] = await Promise.all([
        await this.searchLocation.getSearchResultsAbsoluteUrl(),
        await this.searchSDK.getDocumentTypes(),
      ]);

      if (this.state.isDemoContent) {
        this.setDemoContentOptions({
          documentTypesForSearchResults: documentTypes.map(t => t.documentType),
        });
      }

      const searchRequest = withDocumentType(
        this.state.searchRequest,
        documentTypes,
      );

      const {
        searchResponse,
        searchResponseTotals,
        searchSampleResponse,
      } = await this.search(searchRequest);

      const searchSamples = searchSampleResponse.samples.map(s => ({
        ...s,
        url: this.searchLocation.buildSearchResultsUrl(
          searchResultsAbsoluteUrl,
          {
            query: searchRequest.query,
            documentType: s.documentType,
          },
        ),
      }));

      this.setState({
        searchResultsAbsoluteUrl,
        isLoaded: true,
        searchRequest,
        searchResponse,
        searchResponseTotals,
        searchSamples,
        documentTypes,
        searchRequestStatus: SearchRequestStatus.Loaded,
        error: null,
      });

      void this.biLogger.loadingFinished({
        loadingDuration: Date.now() - startTime,
        success: true,
      });
    } catch (error) {
      this.setState({
        isLoaded: true,
        error: JSON.stringify(error),
        searchRequestStatus: SearchRequestStatus.Failed,
        searchResponse: SearchResultsControllerStore.getEmptySearchResponse(),
      });

      void this.biLogger.loadingFinished({
        loadingDuration: Date.now() - startTime,
        success: false,
        error: error.toString(),
      });

      this.reportError(error);
    }

    // NOTE: on CSR appLoaded is called from componentDidMount
    if (this.isSSR()) {
      this.handleAppLoaded();
    }
  }
}
