import {
  IWidgetController,
  IControllerConfig,
  I$W,
} from '@wix/native-components-infra/dist/src/types/types';

import { getSettingsValues, createEventHandler } from '@wix/tpa-settings';
import { searchResultsSettings } from '@wix/search-results-settings-definitions';

import { IWidgetControllerConfig } from './platform.types';

import { SearchResultsControllerStore } from './SearchResultsControllerStore';

export interface ISearchResultsWixCode {
  changeQuery(query: string): void;
}

export interface ISettingsEvents {
  previewStateChange: {
    shouldHaveSearchResults: boolean;
    shouldSetNonAllDocumentType: boolean;
  };
}

export async function searchResultsControllerFactory(
  controllerConfig: IWidgetControllerConfig,
): Promise<IWidgetController> {
  const publicData = controllerConfig.config.publicData.COMPONENT || {};
  const settings = getSettingsValues(publicData, searchResultsSettings);

  const eventHandler = createEventHandler<ISettingsEvents>(publicData);

  eventHandler.on('previewStateChange', value => {
    controllerStore.updateDemoMode(value);
  });

  eventHandler.onReset(() => {
    controllerStore.updateDemoMode({
      shouldHaveSearchResults: true,
      shouldSetNonAllDocumentType: true,
    });
  });

  let controllerStore: SearchResultsControllerStore;

  try {
    controllerStore = new SearchResultsControllerStore(
      controllerConfig,
      settings,
    );
  } catch (error) {
    controllerConfig.reportError(error);
    throw error;
  }

  return {
    pageReady() {
      return controllerStore.setInitialState();
    },

    updateConfig($w: I$W, config: IControllerConfig) {
      const updatedPublicData = config.publicData.COMPONENT || {};

      eventHandler.notify(updatedPublicData);

      const updatedSettings = getSettingsValues(
        updatedPublicData,
        searchResultsSettings,
      );

      void controllerStore.updateSettings(updatedSettings);
    },

    exports: {
      // NOTE: used for mocks in tests
      searchSDK: controllerStore.getSearchSDK(),
      changeQuery: controllerStore.changeQuery,
    } as ISearchResultsWixCode,
  };
}
