import { ARTIFACT_VERSION } from '../../lib/contants';

const SENTRY_DSN =
  'https://f8bc65823c454879a705f3a0492636bf@sentry.wixpress.com/210';

interface ISentryConfig {
  enabled?: boolean;
  release?: string;
  environment: string;
}

export interface ISentryOptions {
  dsn: string;
  config: ISentryConfig;
}

export type IReportError = (error: Error | ErrorEvent | string) => void;

export const buildSentryOptions = (config: ISentryConfig): ISentryOptions => {
  return {
    dsn: SENTRY_DSN,
    config: {
      enabled: process.env.NODE_PATH === 'production',
      release: ARTIFACT_VERSION,
      ...config,
    },
  };
};
